import React, { Fragment, useState, useEffect } from 'react';
import Breadcrumbs from '../../layout/breadcrumb';
import Loader from '../../layout/loader';
import { Container, Row, Col, Button, Table, Card, } from 'reactstrap';
import { toast } from 'react-toastify';
import moment from 'moment';
import { Link } from 'react-router-dom';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import jwt from 'jsonwebtoken';
import { Chart } from "react-google-charts";
import * as FileSaver from 'file-saver';
import XlsxPopulate from "xlsx-populate";
import Select from 'react-select'


export default function AgentStats() {
  const token = document.cookie.split(`jwt=`).pop().split(';').shift();
  const role = token.length > 0 ? jwt.decode(token).type : "";
  const [loading, setloading] = useState(false);
  const [data, setdata] = useState({});
  const [team, setteam] = useState("");
  const [search, setSearch] = useState("visiteur");
  const [users, setusers] = useState([]);
  const [formUser, setFormUser] = useState("");
  const [selectedId, setselectedId] = useState("");
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [specialityData, setspecialityData] = useState([["Specialité", "Nbr de visite"]]);
  const newspecialityData = [["Specialité", "Nbr de visite"]];
  const [productData, setproductData] = useState([["Specialité", "Nbr de visite"]]);
  const newproductData = [["Produit", "Nbr"]];



  const selectedAgentID = function (user) {
    console.log(user)
    const selectedUser = users.filter((u) => (u.user_lastname + " " + u.user_firstname) === user)[0];
    setselectedId(selectedUser && selectedUser.user_id);
  }

  const getUsers = async () => {
    setloading(true);
    const request = {
      method: "POST",
      headers: {
        "Content-type": "application/json; charset=UTF-8",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers": "Origin, X-requested-With, Content-Type, Accept",
        'Authorization': 'Bearer ' + token
      },
      body: JSON.stringify({
        user_name: null,
        user_network: null
      })
    }
    try {
      var responce = await fetch("https://lsreport.shls.care/api/users/all", request, { mode: "cors" });
      if (responce.status === 200) {
        let data = await responce.json();
        setusers(data);
      } else {
        toast.error(await responce.json());
      }
    } catch (err) {
      setloading(false);
      toast.error(err.message);
    }
    setloading(false);
  }

  const fetchData = async (userID) => {
    setloading(true);
    const request = {
      method: "POST",
      headers: {
        "Content-type": "application/json; charset=UTF-8",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers": "Origin, X-requested-With, Content-Type, Accept",
        'Authorization': 'Bearer ' + token
      },
      body: JSON.stringify({
        mindate: startDate,
        maxdate: endDate,
        team: team,
        user_id: selectedId
      })
    }
    try {
      var responce = await fetch("https://lsreport.shls.care/api/stats/agentsstats", request, { mode: "cors" });
      if (responce.status === 200) {
        let data = await responce.json();
        setdata(data);
        for (let key in data.specialities) {
          newspecialityData.push([key, data.specialities[key]]);
        }
        setspecialityData(newspecialityData);
        for (let key in data.products) {
          newproductData.push([key, data.products[key]]);
        }

        setproductData(newproductData);


      } else {
        toast.error(await responce.json());
      }
    } catch (err) {
      setloading(false);
      toast.error(err.message);
    }
    setloading(false);
  }

  useEffect(() => {
    getUsers();
    window.scrollTo(0, 0);
  }, [])

  const columns = [
    { key: "lastname", value: "Nom" },
    { key: "firstname", value: "Prénom" },
    { key: "progress", value: "Progrès" },
    { key: "nbrreports", value: "Nombre de visites" },
    { key: "nbrdoctor", value: "Médecins" },
    { key: "nbrpharmacy", value: "Pharmaciens" },
    { key: "duals", value: "Visites en duo" },
    { key: "nbrfocus", value: "Focus" },
    { key: "nbrdays", value: "Jours travaillés" },
    { key: "nbrdot", value: "Days off territory" },
    { key: "nbrnotworking", value: "Jours non travaillés" },
    { key: "nbra", value: "A" },
    { key: "nbrb", value: "B" },
    { key: "nbrc", value: "C" },
    { key: "roa", value: "Ratio A" },
    { key: "rob", value: "Ratio B" },
    { key: "roc", value: "Ratio C" },
  ];
  function getSheetData(data, header) {
    // var fields = Object.keys(data[0]);
    var sheetData = data.map(function (row) {
      return columns.map(function (field) {
        return (field.key === "duals" && row.user_type === "agent") ? "Non" : row[field.key] ? row[field.key] : 0;
      });
    });
    sheetData.unshift(header);
    return sheetData;
  }

  async function saveAsExcel() {
    let xlsdata = data.agents ?? [];
    let header = columns.map((column) => { return column.value });

    XlsxPopulate.fromBlankAsync().then(async (workbook) => {
      const sheet1 = workbook.sheet(0);
      const sheetData = getSheetData(xlsdata, header);
      const totalColumns = sheetData[0].length;

      sheet1.cell("A1").value(sheetData);
      const range = sheet1.usedRange();
      const endColumn = String.fromCharCode(64 + totalColumns);
      sheet1.row(1).style("bold", true);
      sheet1.range("A1:" + endColumn + "1").style("fill", "339EFF");
      range.style("border", true);
      return workbook.outputAsync().then((res) => {
        FileSaver.saveAs(res, "Statistiques " + team + " " + moment(Date()).format("DD/MM/yyyy") + ".xlsx");
      });
    });
  }

  const options = 
    users &&
    users.map(user => (
      { value:  user.user_lastname + " " + user.user_firstname, label: user.user_lastname + " " + user.user_firstname }
    ))
  

  const handleChange = (value) => {
    if (value === "visiteur") {
      setteam("")
    } else {
      setteam("Antidouleur")
      setselectedId("")
    }
    setSearch(value)
  }

  const exportDetails = async () => {
    setloading(true);
    const request = {
      method: "POST",
      headers: {
        "Content-type": "application/json; charset=UTF-8",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers": "Origin, X-requested-With, Content-Type, Accept",
        'Authorization': 'Bearer ' + token
      },
      body: JSON.stringify({
        mindate: startDate,
        maxdate: endDate,
        team: team,
        user_id: selectedId
      })
    }
    try {
      var responce = await fetch("https://lsreport.shls.care/api/stats/export", request, { mode: "cors" });
      if (responce.status === 200) {
        const blob = await responce.blob();
        const downloadLink = document.createElement('a');
        const url = window.URL.createObjectURL(blob);
        downloadLink.href = url;
        downloadLink.download = ("Détails Statistiques (" + moment(startDate).format("DD/MM/yyyy") + " / " + moment(endDate).format("DD/MM/yyyy")) + ').xlsx'; // Set the desired file name
        document.body.appendChild(downloadLink);
        downloadLink.click();

        // Clean up resources
        document.body.removeChild(downloadLink);
        window.URL.revokeObjectURL(url);
      } else {
        toast.error(await responce.json());
      }
    } catch (err) {
      setloading(false);
      toast.error(err.message);
    }
    setloading(false);
  }
  return (
    <Fragment>
      <Loader isLoading={loading} />
      <Breadcrumbs parent="Statistiques" title="statistiques Visiteurs" />
      <Container fluid={true} className='pb-4 mb-4'>
        <Row>
          {(() => {
            if (role === "admin" && search === "visiteur")
              return <>
                <Col md="3">
                  <label htmlFor="users">Chercher par</label>
                  <select
                    value={search}
                    onChange={(e) => { handleChange(e.target.value) }}
                  >
                    <option value="visiteur">Visiteur</option>
                    <option value="equipe">Equipe</option>
                  </select>
                </Col>
                <Col md={3}>
                  <label htmlFor="users">Selectionner visiteur</label>
                  <Select name="users" options={options} placeholder='Selectionner visiteur' type="text" list="data" onChange={(e) => { setFormUser(e.value); selectedAgentID(e.value); }} />
                  {/* <datalist id="data">
                    {users.map((user) => {
                      return (
                        <option key={users.indexOf(user)}>{user.user_lastname} {user.user_firstname}</option>
                      )
                    })}
                  </datalist> */}
                </Col>
              </>
            if (role === "admin" && search === "equipe")
              return <>
                <Col md="3">
                  <label htmlFor="users">Chercher par</label>
                  <select
                    value={search}
                    onChange={(e) => { handleChange(e.target.value) }}
                  >
                    <option value="visiteur">Visiteur</option>
                    <option value="equipe">Equipe</option>
                  </select>
                </Col>
                <Col md="3">
                  <label htmlFor="users">Equipe</label>
                  <select
                    value={team}
                    onChange={(e) => { setteam(e.target.value) }}
                  >
                    <option value="Antidouleur">Antidouleur</option>
                    <option value="Diabète">Diabète</option>
                    <option value="Gynéco">Gynéco</option>
                    <option value="Neuropsychiatrie /HGE">Neuropsychiatrie /HGE</option>
                    <option value="Visite Pharma">Visite Pharma</option>
                  </select>
                </Col>
              </>
            else
              return <Col md={3}>
                <label htmlFor="users">Selectionner visiteur</label>
                <input name="users" placeholder='Selectionner visiteur' type="text" list="data" value={formUser} onChange={(e) => { setFormUser(e.target.value); selectedAgentID(e.target.value); }} />
                <datalist id="data">
                  {users.map((user) => {
                    return (
                      <option key={users.indexOf(user)}>{user.user_lastname} {user.user_firstname}</option>
                    )
                  })}
                </datalist>
              </Col>
          })()}

          <Col md="2">
            <label htmlFor="">Du:</label>
            <DatePicker dateFormat={"dd/MM/yyyy"} selected={startDate} maxDate={new Date()} onChange={(date) => setStartDate(date)} />
          </Col>
          <Col md="2">
            <label htmlFor="">Au:</label>
            <DatePicker dateFormat={"dd/MM/yyyy"} minDate={startDate} maxDate={new Date()} selected={endDate} onChange={(date) => setEndDate(date)} />
          </Col>
          <Col md="2">
            <Button color='success' onClick={fetchData} style={{ marginTop: "32px" }}>Rechercher</Button>
          </Col>
        </Row>
        <br />
        {
          Object.keys(data).length !== 0 && <> <Row>
            <Col md="6">
              <Card>
                <Chart
                  width={'100%'}
                  height={'400px'}
                  chartType="PieChart"
                  loader={<div>{"Loading Chart"}</div>}
                  data={specialityData}
                  options={{
                    title: 'Par spécialité',
                    colors: ["#a927f9", "#7366ff", "#51bb25", "#f8d62b", "#f73164"]
                  }}
                  rootProps={{ 'data-testid': '1' }}
                  chartEvents={[
                    {
                      eventName: "ready",
                      callback: ({ chartWrapper, google }) => {
                        var handler = function (e) {
                        };
                        google.visualization.events.addListener(
                          chartWrapper.getChart(),
                          "click",
                          handler
                        );
                      }
                    }
                  ]}
                />
              </Card>
            </Col>
            <Col md="6">
              <Card>
                <Chart
                  width={'100%'}
                  height={'400px'}
                  chartType="PieChart"
                  loader={<div>{"Loading Chart"}</div>}
                  data={productData}
                  options={{
                    title: 'Par produit',
                    colors: ["#a927f9", "#7366ff", "#51bb25", "#f8d62b", "#f73164"] // need lots of colors maybe an external array
                  }}
                  rootProps={{ 'data-testid': '1' }}
                />
              </Card>
            </Col>
          </Row>
            <Row>
              <Col xs="12">
                <h4>Statistiques détaillées</h4>
              </Col>
              <Col xs="12" className='o-auto mb-5'>
                {data.agents && data.agents.length > 0 &&
                  <>
                    <Button color='success' className='my-3' onClick={saveAsExcel}>
                      Exporter &nbsp;
                      <i className="fa fa-table" id="TooltipExample"></i>
                    </Button>
                    <Button color='primary' className='my-3 ml-3' onClick={exportDetails}>
                      Exporter Détails
                      <i className="fa fa-file-excel-o ml-2" id="TooltipExample"></i>
                    </Button>
                  </>
                }
                <Table style={{ backgroundColor: "White" }}>
                  <thead>
                    <tr>
                      <td>Visiteur</td>
                      <td>Progrès</td>
                      <td>Nbr de rapports</td>
                      <td>Médecins</td>
                      <td>Pharmaciens</td>
                      <td>Visite en duo</td>
                      <td>Nbr de focus</td>
                      <td>Jour travaillés</td>
                      <td>Days off territory</td>
                      <td>Jours non travaillés</td>
                      <td>A</td>
                      <td>B</td>
                      <td>C</td>
                      <td>Ratio A</td>
                      <td>Ratio B</td>
                      <td>Ratio C</td>
                      <td>Action</td>
                    </tr>
                  </thead>
                  <tbody>
                    {data.agents && data.agents.map((agent) => {
                      return (
                        <tr key={data.agents.indexOf(agent)}>
                          <td>{agent.lastname} {agent.firstname}
                            <br />
                            <span style={{ color: "DarkBlue" }}>
                              {agent.user_type == "admin" ? "Administrateur" : agent.user_type == "manager" ? "Chéf réseau" : agent.user_type == "supervisor" ? "Superviseur" : agent.user_type == "agent" ? "Visiteur" : ""}
                            </span>
                          </td>
                          <td style={{ color: parseInt(agent.progress) > 85 ? "forestgreen" : parseInt(agent.progress) > 60 ? "orange" : "red" }} >{parseFloat(agent.progress).toFixed(2) + "%"}</td>
                          <td>{agent.nbrreports}</td>
                          <td>{agent.nbrdoctor}</td>
                          <td>{agent.nbrpharmacy}</td>
                          <td>{agent.user_type === "agent" ? "Non" : agent.duals}</td>
                          <td>{agent.nbrfocus}</td>
                          <td>{agent.nbrdays}</td>
                          <td>{agent.nbrdot}</td>
                          <td>{agent.nbrnotworking}</td>
                          <td>{agent.nbra}</td>
                          <td>{agent.nbrb}</td>
                          <td>{agent.nbrc}</td>
                          <td>{parseFloat(agent.roa).toFixed(2) + "%"}</td>
                          <td>{parseFloat(agent.rob).toFixed(2) + "%"}</td>
                          <td>{agent.roc !== null ? parseFloat(agent.roc).toFixed(2) + "%" : 0 + "%"}</td>
                          <td>
                            <Link to={{
                              pathname: `${process.env.PUBLIC_URL}/stats/agents/detailed`,
                              state: {
                                user_type: agent.user_type,
                                id: agent.user_id,
                                name: agent.lastname + " " + agent.firstname,
                                progress: parseFloat(agent.progress).toFixed(2) + "%",
                                total: agent.nbrreports,
                                progres_visites: ((parseInt(agent.nbrreports.split("/")[0]) * 100) / parseInt(agent.nbrreports.split("/")[1])).toFixed(2) + "%",
                                doctors: agent.nbrdoctor,
                                progres_doctors: ((parseInt(agent.nbrdoctor.split("/")[0]) * 100) / parseInt(agent.nbrdoctor.split("/")[1])).toFixed(2) + "%",
                                pharmacy: agent.nbrpharmacy,
                                progres_pharmacy: ((parseInt(agent.nbrpharmacy.split("/")[0]) * 100) / parseInt(agent.nbrpharmacy.split("/")[1])).toFixed(2) + "%",
                                focus: agent.nbrfocus,
                                // progres_focus: ((parseInt(agent.nbrfocus.split("/")[0])*100) / parseInt(agent.nbrfocus.split("/")[1])).toFixed(2) ,
                                nbra: agent.nbra,
                                nbrb: agent.nbrb,
                                nbrc: agent.nbrc,
                                roa: parseFloat(agent.roa).toFixed(2),
                                rob: parseFloat(agent.rob).toFixed(2),
                                roc: agent.roc !== null ? parseFloat(agent.roc).toFixed(2) : "0",
                                work: agent.nbrdays,
                                nowork: agent.nbrnotworking,
                                dot: agent.nbrdot,
                                maxdate: endDate,
                                mindate: startDate,
                              }
                            }}>
                              <i className="fa fa-table" id="TooltipExample"></i>
                            </Link>
                          </td>
                        </tr>
                      )
                    })

                    }
                  </tbody>
                </Table>
              </Col>
            </Row>
          </>
        }


      </Container>
    </Fragment>
  )
}
