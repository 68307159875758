import React, { Fragment, useEffect, useState } from 'react';
import Loader from '../../layout/loader';
import { Container, Row, Col, Table, Card, CardBody, CardHeader, } from 'reactstrap';
import { toast } from 'react-toastify';
import { useLocation, Link } from 'react-router-dom';
import Breadcrumbs from '../../layout/breadcrumb';
import moment from 'moment';
import 'moment/locale/fr';
import jwt from 'jsonwebtoken';

export default function DuoVisitDetail() {
  const token = document.cookie.split(`jwt=`).pop().split(';').shift();
  const role = token.length > 0 ? jwt.decode(token).type : "";
  const user_id = token.length > 0 ? jwt.decode(token).id : "";
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const fiche = location.state && location.state.fiche
  const [rows, setRows] = useState([])
  const [duo_id, setDuoId] = useState([])
  const [total, setTotal] = useState()
  const [priorite_amelioration, setPrioriteAmelioration] = useState()

  const exportDetails = async () => {
    setLoading(true);
    const request = {
      method: "GET",
      headers: {
        "Content-type": "application/json; charset=UTF-8",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers": "Origin, X-requested-With, Content-Type, Accept",
        'Authorization': 'Bearer ' + token
      }
    }
    try {
      var responce = await fetch("https://lsreport.shls.care/api/users/user/duo/rapport?user_id=" + user_id + "&duo_id=" + duo_id, request, { mode: "cors" });
      if (responce.status === 200) {
        const blob = await responce.blob();
        const downloadLink = document.createElement('a');
        const url = window.URL.createObjectURL(blob);
        downloadLink.href = url;
        downloadLink.download = (location.state ? ('Visite Duo ' + location.state.visiteur) : 'Visite Duo ') + '.xlsx';
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
        window.URL.revokeObjectURL(url);
      } else {
        toast.error(await responce.json());
      }
    } catch (err) {
      setLoading(false);
      toast.error(err.message);
    }
    setLoading(false);
  }

  const getRows = () => {
    const rr = []
    if (location.state) {
      for (const key in fiche.items) {
        if ((fiche.items.hasOwnProperty(key)) && (key != "total") && (key != "priorite_amelioration")) {
          const value = fiche.items[key];
          rr.push(value)
        }
      }
      setRows(rr)
    }
  }
  useEffect(() => {
    console.log(location.state);
    if (fiche != "") {
      setLoading(true);
      setDuoId(fiche.duo_id);
      getRows()
      setTotal(fiche.items?.total)
      setPrioriteAmelioration(fiche.items?.priorite_amelioration)
      setLoading(false);
    }
    moment.locale('fr');
    window.scrollTo(0, 0);
  }, [])


  return (
    <Fragment>
      <Loader isLoading={loading} />
      <Breadcrumbs parent="Visites" title="Rapports" hide={false} />
      <Container fluid={true} className='pb-4 mb-4'>
        {((role === "manager") || (role === "admin")) &&
          <Row>
            <Col md={"12"} className='f-right d-print-none'>
              <button className='btn btn-success f-right mt-0 mb-2' onClick={() => { exportDetails() }}><i className='fa fa-file-excel-o mr-2'> </i> Exporter</button>
            </Col>
          </Row>
        }
        <Row className='d-none d-print-block' style={{ marginTop: "50px" }}>
          <Col md={12}>
            <Card>
              <CardHeader className='bg-primary text-center'>
                <h4>Fiche d'évaluation métier - {location.state && moment(fiche?.duo_date).format('MMMM / YYYY')}</h4>
              </CardHeader>
            </Card>
          </Col>
        </Row>

        <Row>
          <Col col={12}>
            <Card>

              <CardBody>
                <Table className='table table-borderless'>
                  <tbody>
                    <tr>
                      <th>Visiteur</th>
                      <td>
                        <Link to={`${process.env.PUBLIC_URL}/users/user:${fiche?.user_id}`}>
                          {fiche && fiche.visitor_lastname + ' ' + fiche.visitor_firstname}
                        </Link>
                      </td>

                      <th>Superviseur</th>
                      <td><Link to={`${process.env.PUBLIC_URL}/users/user:${fiche?.supervisor?.user_id}`}>
                        {fiche && fiche.supervisor_lastname + ' ' + fiche.supervisor_firstname}
                      </Link></td>

                      <th>Date de Duo</th>
                      <td>{location.state && moment(fiche?.duo_date).format('YYYY/MM/DD')}</td>
                    </tr>
                  </tbody>
                </Table>
              </CardBody>
            </Card>
          </Col>
        </Row>

        <Row >
          <Col col={12}>
            <Card>
              <CardBody>
                <Table className='table table-bordered'>
                  <thead>
                    <tr>
                      <th>Secteur Visité</th>
                          {/* {
                            (location.state && fiche?.duo_products) &&<th>Produits Présentés</th>
                          } */}
                      
                      <th>Nombre de Visites</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr >
                      <td>{location.state && fiche?.duo_wilaya}</td>
                    

                        {/* {location.state && fiche?.duo_products?.map((prod) => { return (<tr key={prod.product_id}><td className='w-100'>{prod.product_name} </td><td className='w-100'>{ prod?.product_report[0]?.product_report_quantity ? prod?.product_report[0]?.product_report_quantity : 0 } unité</td></tr>) })} */}
                     
                      <td>

                        <strong>Nombre de médecins spécialistes visités PB:</strong>{location.state && fiche?.duo_meds_pb}  <strong>/PV:</strong>{location.state && fiche?.duo_meds_pv}<br/>

                        <strong>Nombre de médecins généralistes visités PB:</strong>{location.state && fiche?.duo_medg_pb}  <strong>/PV:</strong>{location.state && fiche?.duo_medg_pv}<br/>


                        <strong>Nombre de pharmacies visitées:</strong>{location.state && fiche?.duo_pharm}
                      </td>


                    </tr>

                  </tbody>
                </Table>
              </CardBody>
            </Card>
          </Col>
        </Row>

        <Row className='pb-4 d-print-block'>
          <Col className='pb-4' md={12}>
            <Card className='mb-4'>
              <CardBody style={{ overflow: "auto" }}>
                <Table className='table table-bordered '>


                  <thead className='bg-primary'>
                    <tr >
                      <th className='text-center text-light'>Items</th>
                      <th className='text-center text-light'>Notation:<br /> 1-2-3-4-5</th>
                      <th className='text-center text-light'>Commentaires Responsable</th>
                      <th className='text-center text-light'>Pistes d'amélioration / actions concrètes à mettre en oeuvre </th>
                      <th className='text-center text-light'>Délai</th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      rows.map((row) => {
                        return (
                          <tr key={rows.indexOf(row)}>
                            <td>{row.title}</td>
                            <td>{row.notation}</td>
                            <td>{row.commentaires}</td>
                            <td>{row.actions}</td>
                            <td>{row.delai}</td>
                          </tr>
                        )
                      }
                      )
                    }
                    {(() => {
                      return (
                        <>
                          <tr>
                            <td className='text-center  align-middle'>Total</td>
                            <td>
                              {location.state && total}
                            </td>
                          </tr>

                          <tr>
                            <td className='text-center'>Priorité d'amélioration </td>
                            <td colSpan={4}>
                              {location.state && priorite_amelioration}
                            </td>
                          </tr>
                        </>
                      )
                    }
                    )()
                    }


                  </tbody>

                </Table>
              </CardBody>
            </Card>
          </Col>
        </Row>


      </Container>
    </Fragment >
  )
}